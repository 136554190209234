<template>
    <div class="__help_page">

        <div class="help__header">
            <div class="__header_nav section_padding">
                <div class="_logo">
                    <img height="65px" width="100px" src="../../assets/logo.png" alt="">
                </div>
                <div class="right__nav">
                    <el-dropdown>
                        <el-button id="profile">
                            Dropdown List<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <button class="_btn" @click="$router.push('/overview').catch((err) => {
                                    if (err.name !== 'NavigationDuplicated') throw err;
                                })">Dashboard</button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <button class="_btn" @click="$router.push('/profile').catch((err) => {
                                    if (err.name !== 'NavigationDuplicated') throw err;
                                })">Profile</button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <button class="_btn" @click="$router.push('developer').catch((err) => {
                                    if (err.name !== 'NavigationDuplicated') throw err;
                                })">
                                    Developer
                                </button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <button class="_btn" @click="logout">
                                    Logout
                                </button>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <div class="header_section">
                <div class="help_header_sections">
                    <div class="help_header_inner_section">
                        <h2>
                            Hello, how can we help?
                        </h2>
                        <p>
                            Urna quis nulla vitae, condimentum varius in adipiscing blandit. Vitae sodales id feugiat
                            parturient venenatis.
                        </p>
                        <div class="_search_bar_container">
                            <el-input id="search_bar" placeholder="Search" v-model="query">
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="__help_main_section section_padding">
            <div class="_help_main_navigation">
                <ul>
                    <li @click="open('/help/getting_started')"
                        :class="[$route.path == '/help/getting_started' ? '_help_list_active' : '']">
                        Getting started
                    </li>
                    <li @click="open('/help/faqs')" :class="[$route.path == '/help/faqs' ? '_help_list_active' : '']">
                        FAQ
                    </li>
                    <li @click="open('/help/quick_messaging')"
                        :class="[$route.path == '/help/quick_messaging' ? '_help_list_active' : '']">
                        Quick messaging
                    </li>
                    <li @click="open('/help/voice_messaging')"
                        :class="[$route.path == '/help/voice_messaging' ? '_help_list_active' : '']">
                        Voice messaging
                    </li>
                    <li @click="open('/help/managing_contacts')"
                        :class="[$route.path == '/help/managing_contacts' ? '_help_list_active' : '']">
                        Managing contacts
                    </li>
                    <li @click="open('/help/reports')"
                        :class="[$route.path == '/help/reports' ? '_help_list_active' : '']">
                        Reports
                    </li>
                </ul>
            </div>

            <div class="help__main__sections_wrapper">
                <router-view>
                </router-view>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/state/store.js'

export default {
    data() {
        return {
            query: null
        }
    },
    methods: {
        open(openRoute) {
            if (this.$route.path == openRoute) return
            this.$router.push(openRoute).catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
            })
        },
        logout() {
            store.dispatch('auth/logout').then(res => {
                this.$router.push('/signin').catch((err) => {
                    if (err.name !== "NavigationDuplicated") throw err;
                })
            })
        }
    }
}
</script>

<style  lang="scss">
@import url("../../styles/help.scss");
</style>